import { useRef } from 'react';
import styles from './styles.module.scss';
import Arrow from '../../icons/arrow';

const Item = (props) => (
  <div className={styles.item}>
    <img src={props.src} alt={props.alt} />
  </div>
)

const PHASE_IMAGES = {
  execution: [
    "/content.jpeg",
    "/content2.jpeg",
    "/content3.jpeg",
  ],
  delivery: [
    "/contenta.jpeg",
    "/contentb.jpeg",
  ]
};

export default function Carousel({ phase = "execution" }) {
  const ref = useRef(null);
  const images = PHASE_IMAGES[phase];

  const onNext = () => {
    const elm = ref.current;
    if (!elm) return ""
    const children = elm.children, length = children.length - 1, elmRect = elm.getBoundingClientRect();
    for (let i = length; i >= 0; i--) {
      const child = children[i];
      const rect = child.getBoundingClientRect();
      if (rect.left >= elmRect.left && rect.right <= elmRect.right) {
        if(i === length) return ""
        children[i + 1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        break;
      }
    }
  }
  
  const onPrevious = () => {
    const elm = ref.current;
    if (!elm) return ""
    const children = elm.children, length = children.length - 1, elmRect = elm.getBoundingClientRect();
    for (let i = 0; i <= length; i++) {
      const child = children[i];
      const rect = child.getBoundingClientRect();
      if (rect.left >= elmRect.left && rect.right <= elmRect.right) {
        if(i === 0) return ""
        children[i - 1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        break;
      }
    }
  }

  return (
    <div className='overflow-hidden grid -mx-resp md:mt-16 my-12'>
      <div ref={ref} className={`${styles.root} md:gap-8 gap-5 px-resp flex overflow-hidden`}>
        {images.map((src, index) => (
          <Item key={index} src={src} alt={`Image ${index + 1}`} />
        ))}
      </div>
      <div className='flex justify-center md:gap-8 gap-5 md:mt-8 mt-5 px-resp'>
        <button onClick={onPrevious} className='btn-icon border border-light-1'>
          <Arrow />
        </button>
        <button
          onClick={onNext}
          className='btn-icon border border-light-1 rotate-180'
        >
          <Arrow />
        </button>
      </div>
    </div>
  )
}
