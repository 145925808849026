// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_item__ENPxX {
    display: flex;
    flex: 1 1;
    align-items: center;
    gap: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(14 24 37 / var(--tw-text-opacity, 1))
}
@media (min-width: 1536px) {
    .styles_item__ENPxX {
        flex-direction: column;
        text-align: center
    }
}
.styles_item__ENPxX svg {
    width: 2.75rem
}
@media (min-width: 768px) {
    .styles_item__ENPxX svg {
        width: 3rem
    }
}`, "",{"version":3,"sources":["webpack://./src/components/documentation/styles.module.scss"],"names":[],"mappings":"AACI;IAAA,aAAA;IAAA,SAAA;IAAA,mBAAA;IAAA,YAAA;IAAA,oBAAA;IAAA;AAAA;AAAA;IAAA;QAAA,sBAAA;QAAA;IAAA;AAAA;AAEI;IAAA;AAAA;AAAA;IAAA;QAAA;IAAA;AAAA","sourcesContent":[".item {\n    @apply text-primary-5 flex 2xl:flex-col gap-5 2xl:text-center items-center flex-1;\n    svg {\n        @apply md:w-12 w-11;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `styles_item__ENPxX`
};
export default ___CSS_LOADER_EXPORT___;
