// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_item__V96Ok {
  position: relative;
  display: flex;
  min-width: 16rem;
}
@media (min-width: 768px) {
  .styles_item__V96Ok {
    min-width: 21.25rem;
  }
}
.styles_item__V96Ok::after {
  content: "";
  width: 100%;
  padding-top: 87.06%;
}
.styles_item__V96Ok img {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}`, "",{"version":3,"sources":["webpack://./src/components/carouseltwo/styles.module.scss"],"names":[],"mappings":"AACI;EAAA,kBAAA;EAAA,aAAA;EAAA;AAAA;AAAA;EAAA;IAAA;EAAA;AAAA;AACA;EACI,WAAA;EACA,WAAA;EAAA,mBAAA;AAER;AACQ;EAAA,kBAAA;EAAA,SAAA;EAAA,YAAA;EAAA,WAAA;EAAA,iBAAA;EAAA;AAAA","sourcesContent":[".item {\n    @apply relative md:min-w-[21.25rem] min-w-64 flex;\n    &::after {\n        content: \"\";\n        @apply w-full pt-[87.06%];\n    }\n    img {\n        @apply absolute left-0 w-full h-full object-cover object-center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `styles_item__V96Ok`
};
export default ___CSS_LOADER_EXPORT___;
