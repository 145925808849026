// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_links__gtJRs {
    text-wrap: nowrap
}
.styles_links__gtJRs a, .styles_links__gtJRs button {
    display: flex;
    height: 2rem;
    width: 100%;
    gap: 0.5rem
}

.styles_sublinks__qk84w {
    overflow: hidden;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.styles_sublinks__qk84w a {
    padding-left: 1rem;
    padding-right: 1rem
}
.styles_sublinks__qk84w a:not([class*=active]) {
    --tw-text-opacity: 1;
    color: rgb(181 200 227 / var(--tw-text-opacity, 1))
}
.styles_sublinks__qk84w a:hover {
    color: inherit
}`, "",{"version":3,"sources":["webpack://./src/components/drawer/styles.module.scss"],"names":[],"mappings":"AACI;IAAA;AAAA;AAEI;IAAA,aAAA;IAAA,YAAA;IAAA,WAAA;IAAA;AAAA;;AAKJ;IAAA,gBAAA;IAAA,wBAAA;IAAA,wDAAA;IAAA;AAAA;AAEI;IAAA,kBAAA;IAAA;AAAA;AAEI;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA;AAAA","sourcesContent":[".links {\n    @apply text-nowrap;\n    a, button {\n        @apply flex gap-2 h-8 w-full;\n    }\n}\n\n.sublinks {\n    @apply transition-all overflow-hidden;\n    a {\n        @apply px-4;\n        &:not([class *= 'active']) {\n            @apply text-primary-2;\n        }\n        &:hover {\n            @apply text-inherit;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": `styles_links__gtJRs`,
	"sublinks": `styles_sublinks__qk84w`
};
export default ___CSS_LOADER_EXPORT___;
