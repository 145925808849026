// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_root__Wx7b3 {
  display: grid;
  justify-content: center;
  gap: 2rem;
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity, 1));
}

@media (min-width: 1024px) {

  .styles_root__Wx7b3 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 5rem;
  }
}

.styles_circle__71VQE {
  position: relative;
  display: flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity, 1));
  font-size: .8125rem;
  --tw-text-opacity: 1;
  color: rgb(69 117 182 / var(--tw-text-opacity, 1));
}
.styles_circle__71VQE[class*=completed] {
  --tw-border-opacity: 1;
  border-color: rgb(69 117 182 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(69 117 182 / var(--tw-bg-opacity, 1));
}
.styles_circle__71VQE[class*=active] {
  --tw-border-opacity: 1;
  border-color: rgb(69 117 182 / var(--tw-border-opacity, 1));
}

.styles_item__MH47G {
  position: relative;
  display: flex;
  flex: 1 1;
  align-items: center;
  gap: 0.75rem;
}

@media (min-width: 1024px) {

  .styles_item__MH47G {
    flex-direction: column;
    justify-content: center;
  }
}
.styles_item__MH47G:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 2rem;
  left: 1rem;
  height: 2rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity, 1));
}
@media (min-width: 1024px) {

  .styles_item__MH47G:not(:last-child)::after {
    top: 1rem;
    left: 4rem;
    height: 0px;
    width: 6.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/process/styles.module.scss"],"names":[],"mappings":"AACI;EAAA,aAAA;EAAA,uBAAA;EAAA,SAAA;EAAA,oBAAA;EAAA;AAAA;;AAAA;;EAAA;IAAA,gDAAA;IAAA;EAAA;AAAA;;AAKA;EAAA,kBAAA;EAAA,aAAA;EAAA,YAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA,4DAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA;AAAA;AAEI;EAAA,sBAAA;EAAA,2DAAA;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,sBAAA;EAAA;AAAA;;AAIJ;EAAA,kBAAA;EAAA,aAAA;EAAA,SAAA;EAAA,mBAAA;EAAA;AAAA;;AAAA;;EAAA;IAAA,sBAAA;IAAA;EAAA;AAAA;AAEI;EACI,WAAA;EACA,kBAAA;EAAA,SAAA;EAAA,UAAA;EAAA,YAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA,4DAAA;AACZ;AADY;;EAAA;IAAA,SAAA;IAAA,UAAA;IAAA,WAAA;IAAA;EAAA;AAAA","sourcesContent":[".root {\n    @apply grid lg:grid-cols-5 text-dark-2 justify-center lg:gap-20 gap-8;\n}\n\n\n.circle {\n    @apply w-8 h-8 rounded-full relative border-2 text-primary-0 border-dark-3 text-[.8125rem] flex items-center justify-center;\n    &[class *= \"completed\"] {\n        @apply border-primary-0 bg-primary-0;\n    }\n    &[class *= \"active\"] {\n        @apply border-primary-0;\n    }\n}\n.item {\n    @apply relative flex items-center lg:flex-col gap-3 lg:justify-center flex-1;\n    &:not(:last-child) {\n        &::after {\n            content: \"\";\n            @apply border absolute border-dark-3 h-8 lg:h-0 top-8 lg:w-[6.75rem] lg:top-4 lg:left-16 left-4;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `styles_root__Wx7b3`,
	"circle": `styles_circle__71VQE`,
	"item": `styles_item__MH47G`
};
export default ___CSS_LOADER_EXPORT___;
