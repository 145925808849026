export default function Img(props) {
    return (
        <svg width="53" height="60" viewBox="0 0 53 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20664_541)">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.8883 0.0395508C2.26236 0.0395508 0.133301 2.17049 0.133301 4.79643V55.2724C0.133301 57.9002 2.26236 60.0283 4.8883 60.0283H47.8024C50.4283 60.0283 52.5592 57.8992 52.5592 55.2724V19.0811L34.8367 0.0395508H4.8883Z" fill="#B5C8E3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M52.5621 19.0798V20.0173H40.5631C40.5631 20.0173 34.6456 18.8361 34.8171 13.7295C34.8171 13.7295 35.0121 19.0798 40.4459 19.0798H52.5621Z" fill="#90ADD5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.8403 0V13.6509C34.8403 15.2034 35.8753 19.08 40.5628 19.08H52.5619L34.8403 0Z" fill="#DAE4F1" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.56982 50.3802V30.7827H29.1692V50.3802H9.56982ZM27.1939 32.7149H11.5461V44.5274H27.1939V32.7149ZM18.2098 40.5749L21.8745 35.6474L23.0061 37.6377L24.3139 37.2308L25.2364 42.5099H12.972L16.2495 39.3974L18.2098 40.5749ZM14.5948 37.1624C13.7417 37.1624 13.0489 36.5174 13.0489 35.7205C13.0489 34.9246 13.7417 34.2787 14.5948 34.2787C15.4498 34.2787 16.1417 34.9246 16.1417 35.7205C16.1417 36.5174 15.4498 37.1624 14.5948 37.1624Z" fill="#1C2F4A" />
            </g>
            <defs>
                <clipPath id="clip0_20664_541">
                    <rect width="52.5" height="60" fill="white" transform="translate(0.0834961)" />
                </clipPath>
            </defs>
        </svg>
    )
}