import { useLayoutEffect } from 'react';
import onResize from './lib/onResize';
import Project from './pages/project';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Projectwo from './pages/projectwo';
function App() {
  const d = document.documentElement;
  if (process.env.NODE_ENV !== 'production') d.classList.add('development')

  useLayoutEffect(() => {
    const cb = () => {
      d.style.setProperty('--app-height', window.innerHeight + 'px');
      if ('ontouchstart' in d || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) d.classList.remove('desktop');
      else d.classList.add('desktop');
    }
    return onResize(cb);
  }, [])

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' element={<Home />} />
        <Route path='/chapter' element={<Project />} />
        <Route path='/chaptertwo' element={<Projectwo />} />
      </Routes>
    </>
  );
}

export default App;
