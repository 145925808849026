import Layout from "../../hoc/layout";
import Tabs from "../../ui/tabs";
import Medicines from "./medicines";
import PDFs from "./pdfs";
import { useState } from 'react';

export default function Home() {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (index) => {
        console.log('Tab changed to:', index);
        setSelectedTab(index);
    };

    const renderContent = () => {
        console.log('Rendering content for tab:', selectedTab);
        switch(selectedTab) {
            case 0:
                return (
                    <>
                        <PDFs projectId="PROY24-070" />
                        <h3 className="text-2xl font-baloo mt-resp-1 lg:mb-8 md:mb-6 mb-4">
                            Chapters of the Document
                        </h3>
                        <Medicines projectId="PROY24-070" />
                    </>
                );
            case 1:
                return (
                    <>
                        <PDFs projectId="PROY24-020" />
                        <h3 className="text-2xl font-baloo mt-resp-1 lg:mb-8 md:mb-6 mb-4">
                            Chapters of the Document
                        </h3>
                        <Medicines projectId="PROY24-020" />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Layout>
            <h1 className="font-baloo text-5xl mb-resp-1">Welcome ABM</h1>
            <h2 className="font-baloo text-3xl md:mb-10 mb-8">General Project Documentation</h2>
            <Tabs 
                className="text-xl overflow-auto [&>button]:h-10 xl:gap-16 md:gap-12 gap-8 flex" 
                options={["PROY24-070 ABM", "PROY24-020 INGENIERIA ESTRELLA"]}
                onChange={handleTabChange}
                value={selectedTab}
            />
            <hr className="mt-6 mb-resp-2 border-light-4" />
            {renderContent()}
        </Layout>
    );
}
