import { useState } from "react"

export default function Tabs({ options, onChange, value, className }) {
    return (
        <div className={className}>
            {options.map((option, index) => (
                <button
                    key={index}
                    onClick={() => onChange(index)}
                    className={`${index === value 
                        ? 'text-primary-0 border-b-2 border-primary-0' 
                        : 'text-dark-2 hover:text-primary-0'}`}
                >
                    {option}
                </button>
            ))}
        </div>
    );
}
