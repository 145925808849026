import Check from "../../icons/check"
import styles from './styles.module.scss';

const Item = ({ status, step, title, onClick, isClickable }) => (
    <div 
        className={`${styles.item} ${isClickable ? 'cursor-pointer' : ''}`} 
        onClick={isClickable ? onClick : undefined}
        role={isClickable ? "button" : undefined}
        tabIndex={isClickable ? 0 : undefined}
    >
        <div className={`${styles.circle} ${status}`}>
            {status === 'completed' ? <Check /> : `0${step}`}
        </div>
        <p className={status === "active" ? "text-primary-0" : ""}>{title}</p>
    </div>
)

const Process = ({ currentPhaseIndex = 2, onPhaseChange, showInstallation = false }) => {
    const handlePhaseClick = (index) => {
        // Only allow clicking on completed phases or the next available phase
        if (index <= currentPhaseIndex + 1) {
            onPhaseChange(index);
        }
    };

    return (
        <div className="flex justify-center">
            <div className={styles.root}>
                <Item 
                    status={currentPhaseIndex > 0 ? "completed" : currentPhaseIndex === 0 ? "active" : ""} 
                    title="Creation" 
                    step={1}
                    onClick={() => handlePhaseClick(0)}
                    isClickable={true}
                />
                <Item 
                    status={currentPhaseIndex > 1 ? "completed" : currentPhaseIndex === 1 ? "active" : ""} 
                    title="Design" 
                    step={2}
                    onClick={() => handlePhaseClick(1)}
                    isClickable={currentPhaseIndex >= 0}
                />
                <Item 
                    status={currentPhaseIndex > 2 ? "completed" : currentPhaseIndex === 2 ? "active" : ""} 
                    title="Execution" 
                    step={3}
                    onClick={() => handlePhaseClick(2)}
                    isClickable={currentPhaseIndex >= 1}
                />
                <Item 
                    status={currentPhaseIndex > 3 ? "completed" : currentPhaseIndex === 3 ? "active" : ""} 
                    title="Shipping" 
                    step={4}
                    onClick={() => handlePhaseClick(3)}
                    isClickable={currentPhaseIndex >= 2}
                />
                {showInstallation && (
                    <Item 
                        status={currentPhaseIndex === 4 ? "active" : ""} 
                        title="Installation" 
                        step={5}
                        onClick={() => handlePhaseClick(4)}
                        isClickable={currentPhaseIndex >= 3}
                    />
                )}
            </div>
        </div>
    )
}

export default Process;
