import Download from "../../icons/download"
import Img from "../../icons/img"
import Pdf from "../../icons/pdf"
import styles from './styles.module.scss'

const Item = (props) => {
    return (
        <div className={styles.item}>
            {props.type === 'image' ? <Img /> : <Pdf />}
            <div>
                <p className="text-md">{props.title}</p>
                <a href={props.url} className="text-dark-2 text-base gap-2 underline hover:no-underline btn" download={true}>
                    Download <Download />
                </a>
            </div>
        </div>
    )
}

const PHASE_DOCUMENTS = {
    creation: {
        title: "Creación",
        items: [
            { type: "pdf", title: "RP24-544.06-ABM-UK-STATIONARY TABLE", url: "RP24-544.06-ABM-UK-STATIONARY TABLE.pdf" },
   

        
        ]
    },
    design: {
        title: "Diseño",
        items: [
            { type: "pdf", title: "Plano", url: "/Plano.pdf" },
        ]
    },
    execution: {
        title: "Ejecución",
        items: [
            { type: "image", title: "Foto 1", url: "/content.jpeg" },
            { type: "image", title: "Foto 2", url: "/content2.jpeg" },
            { type: "image", title: "Foto 3", url: "/content3.jpeg" },
        ]
    },
    delivery: {
        title: "Envío",
        items: [
            { type: "image", title: "Foto 1", url: "/contenta.jpeg" },
            { type: "image", title: "Foto 2", url: "/contentb.jpeg" },

        ]
    }
};

export default function Documentation({ currentPhase = "execution" }) {
    const phaseData = PHASE_DOCUMENTS[currentPhase];

    return (
        <div className="w-full">
            <div className="flex flex-col items-center text-center gap-8">
                <div>
                    <b className="text-primary-0 text-base">Documentation</b>
                    <h2 className="font-baloo text-3xl">{phaseData.title}</h2>
                </div>
                <div className="gap-inherit flex 2xl:flex-row flex-col">
                    {phaseData.items.map((item, index) => (
                        <Item 
                            key={index}
                            type={item.type}
                            title={item.title}
                            url={item.url}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
