import Check from "../../icons/check";
import styles from './styles.module.scss';

export default function Checkbox({ title, label, className, ...props }) {
    return (
        <label className={`${styles.root} ${className}`}>
            <input className="hidden" type="checkbox" {...props} />
            <span className={styles.box}>
                <Check />
            </span>
            {title}
        </label>
    )
}