import { NavLink } from "react-router-dom"
import Up from "../../icons/up"
import Bars from "../../icons/bars"
import Close from "../../icons/close"
import styles from './styles.module.scss';
import { useState } from "react";

const Expandable = props => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <button onClick={() => setOpen(!open)}>{props.name} <Up className={open ? '' : 'rotate-180'} /> </button>
            <ul className={`${styles.sublinks} ${open ? 'max-h-96' : 'max-h-0 opacity-0'}`}>
                {props.sublinks.map((sublink, index) => (
                    <li key={index}>
                        <NavLink to={sublink.to}>{sublink.name}</NavLink>
                    </li>
                ))}
            </ul>
        </>
    )

}
const links = [
    {
        name: 'Projects',
        sublinks: [
            { name: 'PROY24-020 INGENIERIA ESTRELLA', to: '/' },
          
   
        ]
    },
    { name: 'Settings', to: '/settings' },
]

export default function Drawer() {
    const [open, setOpen] = useState(false)
    return (
        <>
            {open && <div className="lg:hidden z-50 bg-black bg-opacity-40 fixed top-0 left-0 w-full h-full" />}

            <div className={`bg-primary-0 xl:px-8 z-50 md:py-14 md:px-6 pb-8 pt-28 p-4 text-white h-dvh flex flex-col md:gap-10 gap-6 overflow-auto lg:sticky top-0 fixed transition-all ${styles.root} ${open ? 'left-0' : '-left-56'}`}>
                <img 
                    src="/logo.png" 
                    alt="" 
                    className="md:block hidden mt-2 h-10 w-auto object-contain" 
                />
                <hr className="text-primary-2 border-current md:block hidden" />
                <div>
                    <img src="/avatar.png" className="w-10 h-10 object-cover rounded-full object-center" alt="" />
                    <p className="text-primary-1 mt-2">Nombre Usuario</p>
                </div>
                <ul className={styles.links}>
                    {links.map((link, index) => (
                        <li key={index}>
                            {link.to ? <NavLink to={link.to}>{link.name}</NavLink> : <Expandable {...link} />}
                        </li>
                    ))}
                </ul>
            </div>
            <nav className="flex sticky top-0 lg:hidden items-center z-50 justify-between bg-primary-0 py-3 px-8">
                <img alt="" src="/logo.png" className="h-10" />
                <button onClick={() => setOpen(!open)}>
                    {open ? <Close /> : <Bars />}
                </button>
            </nav>
        </>
    )
}
