import styles from './styles.module.scss';

export default function withField(Component) {
    return ({ title, left, right, className, ...props }) => (
        <label title={title} className={`${styles.root} ${className}`}>
            <span className={styles.main}>
                {left}
                <Component {...props} className={styles.field} />
                {right}
            </span>
        </label>
    )
}