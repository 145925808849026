// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_root__VUMed {
  display: grid;
  gap: .625em;
  font-size: 1rem;
  line-height: 1.5rem;
}
.styles_root__VUMed[title]::before {
  content: attr(title);
}

.styles_field__SkqIa {
  min-height: 3.5em;
  flex: 1 1;
  border-radius: inherit;
  padding-left: 1.25em;
  padding-right: 1.25em;
}
.styles_field__SkqIa:first-child {
  margin-left: -1.25em;
}
.styles_field__SkqIa:last-child {
  margin-right: -1.25em;
}
.styles_field__SkqIa::placeholder {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity, 1));
}

.styles_main__RXJn6 {
  display: flex;
  align-items: center;
  border-radius: 0.625em;
  border-width: 1px;
  border-color: rgb(51 51 51 / var(--tw-border-opacity, 1));
  --tw-border-opacity: 0.15;
  padding-left: 1.25em;
  padding-right: 1.25em;
}
.styles_main__RXJn6:has(:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(69 117 182 / var(--tw-border-opacity, 1));
}
.styles_main__RXJn6 > svg {
  --tw-text-opacity: 1;
  color: rgb(112 112 112 / var(--tw-text-opacity, 1));
}`, "",{"version":3,"sources":["webpack://./src/hoc/withField/styles.module.scss"],"names":[],"mappings":"AACI;EAAA,aAAA;EAAA,WAAA;EAAA,eAAA;EAAA;AAAA;AACA;EACI,oBAAA;AAER;;AAGI;EAAA,iBAAA;EAAA,SAAA;EAAA,sBAAA;EAAA,oBAAA;EAAA;AAAA;AAEI;EAAA;AAAA;AAGA;EAAA;AAAA;AAGA;EAAA,oBAAA;EAAA;AAAA;;AAKJ;EAAA,aAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,iBAAA;EAAA,yDAAA;EAAA,yBAAA;EAAA,oBAAA;EAAA;AAAA;AAEI;EAAA,sBAAA;EAAA;AAAA;AAGA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".root {\n    @apply grid gap-[.625em] text-base;\n    &[title]::before {\n        content: attr(title);\n    }\n}\n\n.field {\n    @apply min-h-[3.5em] px-[1.25em] flex-1 rounded-inherit;\n    &:first-child {\n        @apply -ml-[1.25em];\n    }\n    &:last-child {\n        @apply -mr-[1.25em];\n    }\n    &::placeholder {\n        @apply text-dark-5;\n    }\n}\n\n.main {\n    @apply border border-dark-5 border-opacity-15 px-[1.25em] rounded-[0.625em] flex items-center;\n    &:has(:focus) {\n        @apply border-primary-0;\n    }\n    >svg {\n        @apply text-light-3;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `styles_root__VUMed`,
	"field": `styles_field__SkqIa`,
	"main": `styles_main__RXJn6`
};
export default ___CSS_LOADER_EXPORT___;
