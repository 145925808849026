export default function Pdf(props) {
    return (
        <svg {...props} width="54" height="60" viewBox="0 0 54 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20664_154)">
                <path d="M5.53125 0C2.90625 0 0.75 2.15625 0.75 4.78125V55.2188C0.75 57.8438 2.90625 60 5.53125 60H48.4688C51.0938 60 53.25 57.8438 53.25 55.2188V19.0312L35.5312 0H5.53125Z" fill="#B5C8E3" />
                <path d="M53.25 19.125V20.0625H41.25C41.25 20.0625 35.3437 18.8438 35.5312 13.7812C35.5312 13.7812 35.7187 19.125 41.1562 19.125H53.25Z" fill="#90ADD5" />
                <path d="M35.5312 0V13.6875C35.5312 15.2812 36.5625 19.125 41.25 19.125H53.25L35.5312 0Z" fill="#DAE4F1" />
                <path d="M14.7187 45.9375H11.625V49.7812C11.625 50.1562 11.3437 50.4375 10.875 50.4375C10.5 50.4375 10.2188 50.1562 10.2188 49.7812V40.2188C10.2188 39.6563 10.6875 39.1875 11.25 39.1875H14.7187C16.9688 39.1875 18.2813 40.7813 18.2813 42.5625C18.2813 44.4375 16.9688 45.9375 14.7187 45.9375ZM14.625 40.4062H11.625V44.7188H14.625C15.9375 44.7188 16.875 43.875 16.875 42.5625C16.875 41.25 15.9375 40.4062 14.625 40.4062ZM24.375 50.4375H21.5625C21 50.4375 20.5313 49.9688 20.5313 49.4063V40.2188C20.5313 39.6563 21 39.1875 21.5625 39.1875H24.375C27.8438 39.1875 30.1875 41.625 30.1875 44.8125C30.1875 48 27.9375 50.4375 24.375 50.4375ZM24.375 40.4062H21.9375V49.125H24.375C27.0938 49.125 28.6875 47.1563 28.6875 44.7188C28.7812 42.375 27.1875 40.4062 24.375 40.4062ZM39.6563 40.4062H34.2188V44.0625H39.5625C39.9375 44.0625 40.125 44.3438 40.125 44.7188C40.125 45.0938 39.8438 45.2812 39.5625 45.2812H34.2188V49.7812C34.2188 50.1562 33.9375 50.4375 33.4688 50.4375C33.0938 50.4375 32.8125 50.1562 32.8125 49.7812V40.2188C32.8125 39.6563 33.2812 39.1875 33.8438 39.1875H39.6563C40.0313 39.1875 40.2188 39.4688 40.2188 39.8438C40.3125 40.125 40.0313 40.4062 39.6563 40.4062Z" fill="#1C2F4A" />
            </g>
            <defs>
                <clipPath id="clip0_20664_154">
                    <rect width="52.5" height="60" fill="white" transform="translate(0.75)" />
                </clipPath>
            </defs>
        </svg>
    )
}