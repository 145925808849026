import Download from "../../icons/download"

const Item = (props) => (
    <label className="text-md rounded-xl cursor-pointer bg-primary-6 text-center md:min-w-64 md:w-64 border border-primary-1 has-[input:checked]:border-primary-0 hover:border-primary-0 p-5">
        <input value={props.title} defaultChecked={props.checked} type="radio" name="home-pdf" className="hidden" />
        <Download className="md:w-12 w-11 mx-auto" />
        <span className="block mt-2">{props.title}</span>
        <a href={props.href} className="text-dark-2 text-base gap-2 underline hover:no-underline btn" download={true} target="_blank" rel="noreferrer">Download <Download /></a>
    </label>
)

export default function PDFs({ projectId }) {
    // Define items for each project
    const items = {
        "PROY24-070": [
            {
                href: "https://moldtechsl.sharepoint.com/sites/MoldesTecnolgicosparaelHormignS.L/Lists/mymoldtech_proyectos/Attachments/3/PROY20-031.png",
                checked: true,
                title: "PROY20-031.png"
            }
        ],
        "PROY24-020": [
            {
                href: "https://moldtechsl.sharepoint.com/sites/MoldesTecnolgicosparaelHormignS.L/Lists/mymoldtech_proyectos/Attachments/4/PROY24-020.jpg",
                checked: true,
                title: "PROY24-020.jpg"
            }
        ]
    };

    return (
        <div className="md:flex grid grid-cols-2 md:gap-8 gap-5 items-start flex-wrap">
            {items[projectId]?.map((item, index) => (
                <Item
                    key={index}
                    href={item.href}
                    checked={item.checked}
                    title={item.title}
                />
            ))}
        </div>
    );
}
