// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_root__6eqUC {

    display: flex;

    gap: .375em;

    color: rgb(51 51 51 / var(--tw-text-opacity, 1));

    --tw-text-opacity: 0.6
}

.styles_box__7Ytsd {

    display: flex;

    height: 1.5em;

    width: 1.5em;

    align-items: center;

    justify-content: center;

    border-radius: .3125em;

    border-width: 1px;

    border-color: rgb(51 51 51 / var(--tw-border-opacity, 1));

    --tw-border-opacity: 0.15
}

input:checked + .styles_box__7Ytsd {

    --tw-bg-opacity: 1;

    background-color: rgb(69 117 182 / var(--tw-bg-opacity, 1))
}`, "",{"version":3,"sources":["webpack://./src/ui/checkbox/styles.module.scss"],"names":[],"mappings":"AACI;;IAAA,aAAA;;IAAA,WAAA;;IAAA,gDAAA;;IAAA;AAAA;;AAIA;;IAAA,aAAA;;IAAA,aAAA;;IAAA,YAAA;;IAAA,mBAAA;;IAAA,uBAAA;;IAAA,sBAAA;;IAAA,iBAAA;;IAAA,yDAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA;AAAA","sourcesContent":[".root {\n    @apply flex gap-[.375em] text-dark-5 text-opacity-60;\n}\n\n.box {\n    @apply border border-dark-5 flex items-center justify-center border-opacity-15 w-[1.5em] h-[1.5em] rounded-[.3125em];\n}\n\ninput:checked + .box {\n    @apply bg-primary-0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `styles_root__6eqUC`,
	"box": `styles_box__7Ytsd`
};
export default ___CSS_LOADER_EXPORT___;
