import { Link } from "react-router-dom";
import Drawer from "../components/drawer";


export default function Layout(props) {
    return (
        <div className="lg:flex">
            <Drawer />
            <div className="flex-1 font-verdana mt-resp-1 mx-resp flex flex-col">
                {props.children}
                <div className="py-8 text-base font-inter mt-resp-1 -mx-resp px-resp text-light-3 bg-light-2 flex md:flex-row flex-col md:gap-6 gap-4 justify-between">
                    <p>© 2024 Moldtech. All rights reserved.</p>
                    <ul className="flex gap-4">
                        <li><Link className="hover:underline" to='/'>Terms</Link></li>
                        <li><Link className="hover:underline" to='/'>Privacy</Link></li>
                        <li><Link className="hover:underline" to='/'>Cookies</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}