import { Link } from "react-router-dom"

const Item = props => (
    <div className="flex sm:items-center flex-col sm:flex-row justify-between text-dark-2 gap-2">
        <div>
            <h4 className="text-lg">
                {props.medicine} {props.width && `W=${props.width}`} {props.length && `L=${props.length}`}: {props.fill && <>F=<b className="text-primary-0">{props.fill}</b></>}
            </h4>
          
        </div>
        <Link to={props.link} className="btn-primary text-base">Go to chapter</Link>
    </div>
)

export default function Medicines({projectId}) {
    // Define items for each project with their specific links
    const items = {
        "PROY24-070": {
            medicine: "STATIONARY TABLES",
            link: "/chapter"  // specific link for this item
        },
        "PROY24-020": {
            medicine: "MOLDE AUTOPORTANTE PLACA TT",
            link: "/chaptertwo"  // specific link for this item
        }
    };

    return (
        <div>
            <Item 
                {...items[projectId]}
            />
        </div>
    )
}
