import { useState } from "react";
import Carousel from "../components/carousel";
import Documentation from "../components/documentation";
import Process from "../components/process";
import Layout from "../hoc/layout";

const PHASES = [
    { id: 'creation', name: 'Creation', date: 'October 15, 2024' },
    { id: 'design', name: 'Design', date: 'November 20, 2024' },
    { id: 'execution', name: 'Execution', date: 'December 8, 2024' },
    { id: 'delivery', name: 'Delivery', date: 'January 1, 2025' }
];

export default function Project() {
    const [currentPhaseIndex, setCurrentPhaseIndex] = useState(2); // Starting with execution phase
    const currentPhase = PHASES[currentPhaseIndex];
    
    return (
        <Layout>
            <b className="font-verdana mx-auto py-[0.285em] bg-primary-1 rounded-full text-primary-3 px-[1em]">PROY24-070 ABM</b>
            <h1 className="font-baloo text-5xl mx-auto md:mt-4 md:mb-5 my-3 max-w-5xl text-center ">ABM , your mould is in progress</h1>
            <p className="mx-auto text-primary-4 text-xl">40% completed</p>
            <img 
                alt="" 
                src="/PROY20-031.png" 
                className="xl:mt-16 md:mt-10 mt-8 max-w-3xl mx-auto w-full" 
            />
            <p className="text-base mx-auto font-verdana text-dark-2">STATIONARY TABLES W=1,6M L=100M: F=60%</p>
            <div className="lg:my-[3.25rem] text-center md:my-11 my-8">
                <h4 className="text-md font-bold text-primary-0">We are working on your order</h4>
                <h2 className="text-3xl font-baloo my-3">Chapter phases</h2>
                <p className="text-dark-2 text-xl">Estimated delivery: January 1, 2025</p>
            </div>
            <Process 
                currentPhaseIndex={currentPhaseIndex} 
                onPhaseChange={setCurrentPhaseIndex}
            />
            
            {(currentPhase.id === 'execution' || currentPhase.id === 'delivery') ? (
                <Carousel phase={currentPhase.id} />
            ) : (
                <div className="lg:my-16 md:my-12 my-8">
                    <Documentation currentPhase={currentPhase.id} />
                </div>
            )}

            <div className="text-center lg:my-24 my-12">
                <b className="text-base text-primary-0">Next phase:</b>
                <h2 className="font-baloo text-3xl mt-3 mb-5">
                    {currentPhaseIndex < PHASES.length - 1 
                        ? PHASES[currentPhaseIndex + 1].name 
                        : 'Installation'}
                </h2>
                <p className="text-xl">
                    Estimated delivery: {currentPhaseIndex < PHASES.length - 1 
                        ? PHASES[currentPhaseIndex + 1].date 
                        : PHASES[PHASES.length - 1].date}
                </p>
            </div>
            <img src="/map.webp" className="min-h-[25rem] rounded-xl object-cover object-center" alt="" />
        </Layout>
    )
}
